import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Registration.css';
import Error from '../notifications/Error';
import Succes from '../notifications/Succes';
import config from '../config';
import { getJwtToken } from '../auth';

// Import the eye icons
import eyeOpen from '../images/eye_open.png';
import eyeClosed from '../images/eye_closed.png';

const reactServer = config.reactServer;

const RegistrationForm: React.FC = () => {
  const [naam, setNaam] = useState('');
  const [email, setEmail] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [herhaal, setHerhaal] = useState('');
  const [termsAndServices, setTermsAndServices] = useState(false);
  const [liscensieOvereenkomst, setLiscensieOvereenkomst] = useState(false);
  const [twoStepAvailable, setTwoStepAvailable] = useState(false);
  const [twoStepCode, setTwoStepCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [organization, setOrganization] = useState('');
  const [contractFile, setContractFile] = useState<File | null>(null);
  const [organisationsList, setOrganisationsList] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [organisationCode, setOrganisationCode] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false); 


  const navigate = useNavigate();
  const token = getJwtToken();

  // Fetch organizations on component mount
  useEffect(() => {
    const fetchOrganisations = async () => {
      try {
        const response = await fetch(reactServer + 'organisation/get_all', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setOrganisationsList(['Geen organisatie', ...data]);
        } else {
          setError('Fout bij het ophalen van organisaties.');
        }
      } catch (error) {
        setError('Geen verbinding met de server.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrganisations();
  }, [token]);

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*\W).{12,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      naam: naam,
      email: email.toLowerCase(),
      wachtwoord: wachtwoord,
      termsAndServices: termsAndServices,
      liscensieOvereenkomst: liscensieOvereenkomst,
      organization: organization,
      contractFile: contractFile,
      organisationCode: organization !== 'Geen organisatie' ? organisationCode : '',
    };

    // Validate form fields
    if (!naam || !email || !wachtwoord || !herhaal) {
      setError('Alle velden dienen ingevuld te worden.');
      return;
    }

    if (!organization) {
      setError('Selecteer uw organisatie (of kies voor de "Geen organisatie" optie).');
      return;
    }

    if (!validatePassword(wachtwoord)) {
      setError('Het wachtwoord moet minimaal 12 karakters lang zijn en ten minste één hoofdletter en één symbool bevatten.');
      return;
    }

    if (herhaal !== wachtwoord) {
      setError('Wachtwoorden komen niet overeen.');
      return;
    }

    if (!email.includes('@')) {
      setError('Dit is geen geldig e-mailadres.');
      return;
    }

    if (!termsAndServices) {
      setError('Je moet akkoord gaan met de gebruikersvoorwaarden.');
      return;
    }

    if (organization === 'Geen organisatie') {
      if (!liscensieOvereenkomst) {
        setError('Je moet akkoord gaan met de licentieovereenkomst.');
        return;
      }
    }

    try {
      const response = await fetch(reactServer + 'account/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'Er is een fout opgetreden.');
        return;
      }

      if (response.status === 201) {
        setSuccess(data.message);
        setTwoStepAvailable(true);
        localStorage.setItem('email', email.toLowerCase());
      } else if (response.status === 200) {
        setSuccess(data.message);
        localStorage.setItem('email', email.toLowerCase());
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('Geen verbinding met de server.');
    }
  };

  const handleVerification = async (email: string, code: string) => {
    const data = {
      email: email.toLowerCase(),
      code: code,
    };

    try {
      const response = await fetch(reactServer + 'account/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate('/login', { replace: true });
      } else {
        const responseData = await response.json();
        setError(responseData.message);
      }
    } catch (error) {
      setError('Geen verbinding met de server.');
    }
  };

  const handleVerificationSubmit = async () => {
    await handleVerification(email, twoStepCode);
  };

  const handleContractFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setContractFile(files[0]);
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  const handleCloseSucces = () => {
    setSuccess('');
  };

  return (
    <div className="registration-container">
      {error && <Error message={error} onClose={handleCloseError} />}
      {success && <Succes message={success} onClose={handleCloseSucces} />}
      <h1 className="page-title">Registratie</h1>
      
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className='registration-aanbieding'>Meld u zich aan zonder een organisatie dan kunt u 5 keer gratis gebruik maken van de Tolkchat.</div>
        <input
          type="text"
          name="naam"
          placeholder="Volledige naam"
          value={naam}
          onChange={(event) => setNaam(event.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
        />

        {/* Password Field with Toggle */}
        <div className="password-field">
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            name="wachtwoord"
            placeholder="Wachtwoord"
            value={wachtwoord}
            onChange={(event) => setWachtwoord(event.target.value)}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
          >
            <img
              src={showPassword ? eyeClosed : eyeOpen}
              alt={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
              className="eye-icon"
            />
          </button>
        </div>

        {/* Repeat Password Field with Toggle */}
        <div className="password-field">
          <input
            type={showRepeatPassword ? 'text' : 'password'}
            autoComplete="new-password"
            name="herhaal"
            placeholder="Herhaal wachtwoord"
            value={herhaal}
            onChange={(event) => setHerhaal(event.target.value)}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
            aria-label={showRepeatPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
          >
            <img
              src={showRepeatPassword ? eyeClosed : eyeOpen}
              alt={showRepeatPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
              className="eye-icon"
            />
          </button>
        </div>

        <select
          name="organization"
          value={organization}
          onChange={(event) => setOrganization(event.target.value)}
        >
          
          <option value="" disabled>Selecteer organisatie</option>
          <option value="Geen organisatie">Geen organisatie</option>
          {loading ? (
            <option value="" disabled>
              Laden...
            </option>
          ) : (
            organisationsList.map((org, index) => (
              <option key={index} value={org}>
                {org}
              </option>
            ))
          )}
        </select>

        {organization && organization !== 'Geen organisatie' && (
          <input
            type="text"
            name="organisationCode"
            placeholder="Organisatie code (optioneel)"
            value={organisationCode}
            onChange={(event) => setOrganisationCode(event.target.value)}
          />
        )}

        {organization === 'Geen organisatie' && (
          <div className="licensie">
            <input
              type="checkbox"
              className="licensieinput"
              name="liscensieOvereenkomst"
              checked={liscensieOvereenkomst}
              onChange={(event) => setLiscensieOvereenkomst(event.target.checked)}
            />
            <label htmlFor="liscensieOvereenkomst">
              Ik heb de{' '}
              <a href="/overeenkomsten/Licentieovereenkomst_Wellcom_Translation.docx" download>
                licentieovereenkomst
              </a>{' '}
              gelezen en ga akkoord.
            </label>
          </div>
        )}

        <div className="terms-and-services">
          <input
            type="checkbox"
            name="termsAndServices"
            checked={termsAndServices}
            onChange={(event) => setTermsAndServices(event.target.checked)}
          />
          <label htmlFor="termsAndServices">
            Ik heb de eindgebruikersvoorwaarden gelezen en ik ga akkoord met{' '}
            <a href="/overeenkomsten/Gebruikersovereenkomst_Wellcom_Translation.docx" download>
              deze
            </a>{' '}
            voorwaarden.*
          </label>
        </div>
        <button type="submit" className="submit-button">Registreren</button>

        {twoStepAvailable && (
          <div>
            <label style={{ marginTop: '15px' }} htmlFor="termsAndServices">
              We hebben u een mail verzonden met een verificatiecode (check uw spam).
            </label>
            <input
              type="code"
              name="code"
              placeholder="Code"
              className="twostepcode"
              value={twoStepCode}
              onChange={(event) => setTwoStepCode(event.target.value)}
            />
            <button type="button" onClick={handleVerificationSubmit}>
              Bevestigen
            </button>
          </div>
        )}
      </form>
      <div className="bottomblock">
        <div className="same-line login-pagina-tekst">
          <Link to="/register-organisation" className="link">
            Wilt u uw organisatie aanmelden?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
