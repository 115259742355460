// config.js
const config = {
    // reactServer: process.env.REACT_Server || 'http://localhost:5000/', 
    // reactServer: process.env.REACT_Server || 'https://OTAP-WellcomAdmin.eu.pythonanywhere.com/',
    reactServer: process.env.REACT_Server || 'https://LIVE-WellcomAdmin.eu.pythonanywhere.com/',
    // reactServer: process.env.REACT_Server || 'https://thijsrood.pythonanywhere.com/',
    // googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'your_google_api_key',
    // Add more variables as needed
  };
  
  export default config;
  