import React, { useState, useEffect } from 'react';
import { getJwtToken } from '../auth'; // Adjust the import based on your auth setup
import config from '../config';
import './mijnbedrijf.css'; // Ensure this CSS file is available

const reactServer = config.reactServer;

interface Member {
    name: string;
    email: string;
    in_organisation: boolean;
}

interface Organisation {
    code: string;
    type: string;
    deelnemer: number;
    organisatie_bevestigd: boolean;
    subscriptiontype: string;
    deelnemers: number;
    members?: Member[];
}

const MijnBedrijf: React.FC = () => {
    const [organisation, setOrganisation] = useState<Organisation | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
    const [userOrganisation, setUserOrganisation] = useState<string | null>(null);
    const [newMemberEmail, setNewMemberEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const fetchProfileAndOrg = async () => {
            const token = getJwtToken();
            try {
                // Fetch user profile
                const profileResponse = await fetch(reactServer + 'account/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!profileResponse.ok) {
                    throw new Error(`HTTP error! Status: ${profileResponse.status}`);
                }

                const profileData = await profileResponse.json();
                setIsAdmin(profileData.is_admin); // Assuming is_admin indicates if the user is an admin
                const orgName = profileData.organisation[0]; // Assuming organisation[0] gives the org name
                setUserOrganisation(orgName);

                // Fetch organisation details
                const orgResponse = await fetch(reactServer + `organisation/get_organisation_details/${orgName}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!orgResponse.ok) {
                    throw new Error(`HTTP error! Status: ${orgResponse.status}`);
                }

                const orgData = await orgResponse.json();
                setOrganisation(orgData.details);
                if (isAdmin) {
                    // Admin should receive members list
                    setOrganisation(prev => ({
                        ...prev,
                        members: orgData.members,
                    }));
                }
            } catch (error) {
                console.error('Error fetching profile or organisation:', error);
            }
        };

        fetchProfileAndOrg();
    }, []);

    const handleAddMember = async () => {
        if (isAdmin && userOrganisation && newMemberEmail) {
            const token = getJwtToken();
            try {
                const response = await fetch(reactServer + 'organisation/add_member', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ org_name: userOrganisation, email: newMemberEmail }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Fetch updated organisation details
                const orgResponse = await fetch(reactServer + `organisation/get_organisation_details/${userOrganisation}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!orgResponse.ok) {
                    throw new Error(`HTTP error! Status: ${orgResponse.status}`);
                }

                const orgData = await orgResponse.json();
                setOrganisation(orgData.details);
                setOrganisation(prev => ({
                    ...prev,
                    members: orgData.members,
                }));
                setNewMemberEmail(''); // Clear the input field
                setError(''); // Clear any previous errors
            } catch (error) {
                setError('Error adding new member. Please try again.');
                console.error('Error adding member:', error);
            }
        } else {
            setError('Please enter a valid email address.');
        }
    };

    const handleRemoveMember = async (email: string) => {
        if (isAdmin && userOrganisation) {
            const token = getJwtToken();
            try {
                const response = await fetch(reactServer + 'organisation/remove_member', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ org_name: userOrganisation, email }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Fetch updated organisation details
                const orgResponse = await fetch(reactServer + `organisation/get_organisation_details/${userOrganisation}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!orgResponse.ok) {
                    throw new Error(`HTTP error! Status: ${orgResponse.status}`);
                }

                const orgData = await orgResponse.json();
                setOrganisation(orgData.details);
                setOrganisation(prev => ({
                    ...prev,
                    members: orgData.members,
                }));
            } catch (error) {
                setError('Error removing member. Please try again.');
                console.error('Error removing member:', error);
            }
        }
    };

    if (organisation === null || isAdmin === null) {
        // Show a loading spinner or message while fetching data
        return <div>Loading...</div>;
    }

    return (
        <div className="mijnbedrijf-container">
            <h2>Organisation Details</h2>
            <table className="organisation-info-table">
                <tbody>
                    <tr>
                        <th>Code</th>
                        <td>{organisation.code}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td>{organisation.type}</td>
                    </tr>
                    <tr>
                        <th>Deelnemer</th>
                        <td>{organisation.deelnemer}</td>
                    </tr>
                    <tr>
                        <th>Organisatie Bevestigd</th>
                        <td>{organisation.organisatie_bevestigd ? 'Ja' : 'Nee'}</td>
                    </tr>
                    <tr>
                        <th>Subscription Type</th>
                        <td>{organisation.subscriptiontype}</td>
                    </tr>
                    <tr>
                        <th>Aantal Deelnemers</th>
                        <td>{organisation.deelnemers}</td>
                    </tr>
                </tbody>
            </table>

            {isAdmin && organisation.members && (
                <>
                    <h3>Members</h3>
                    <table className="members-table">
                        <thead>
                            <tr>
                                <th>Member Name</th>
                                <th>Email</th>
                                <th>In Organisation</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organisation.members.map((member, index) => (
                                <tr key={index}>
                                    <td>{member.name}</td>
                                    <td>{member.email}</td>
                                    <td>{member.in_organisation ? 'Yes' : 'No'}</td>
                                    <td>
                                        {member.in_organisation && (
                                            <button onClick={() => handleRemoveMember(member.email)}>Remove</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}

            {!isAdmin && (
                <div className="regular-user-message">
                    <p>As a regular user, you do not have access to the member list. Please contact your organisation's admin for more details.</p>
                </div>
            )}

            {isAdmin && (
                <div className="add-member-container">
                    <h3>Add New Member</h3>
                    <input
                        type="email"
                        value={newMemberEmail}
                        onChange={(e) => setNewMemberEmail(e.target.value)}
                        placeholder="Enter member's email"
                    />
                    <button onClick={handleAddMember}>Add Member</button>
                    {error && <div className="error-message">{error}</div>}
                </div>
            )}
        </div>
    );
};

export default MijnBedrijf;
