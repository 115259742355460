import React, { useState, useRef, useEffect } from 'react';
import './languageselector.css'; // Ensure to import the CSS file
import languageMapping from '../mappings/extrainfo'; // Import the mapping file

const LanguageSelector = ({ allLanguages, fromLanguage, setFromLanguage }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Filter languages based on the search query
    const filteredLanguages = allLanguages.filter(lang =>
        languageMapping[lang]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lang.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='language-selector' ref={dropdownRef}>
            <div
                className='selected-language'
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                {languageMapping[fromLanguage] || fromLanguage}
            </div>
            {dropdownOpen && (
                <div className='language_dropdown'>
                    <input
                        type='text'
                        placeholder='Search language...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className='search-input'
                        autoFocus
                    />
                    <ul className='language-list'>
                        {filteredLanguages.length > 0 ? (
                            filteredLanguages.map(lang => (
                                <li
                                    key={lang}
                                    onClick={() => {
                                        setFromLanguage(lang);
                                        setDropdownOpen(false);
                                        setSearchQuery(''); // Clear search query after selection
                                    }}
                                >
                                    {languageMapping[lang] || lang}
                                </li>
                            ))
                        ) : (
                            <li className='no-results'>No languages found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
