import React, { useState, useEffect } from 'react';
import './SettingsMenu.css';

import { getJwtToken } from '../auth';
import config from '../config';
const reactServer = config.reactServer;

interface SettingsMenuProps {
  isAutoPlayEnabled: boolean;
  setAutoPlay: (enabled: boolean) => void;
  language: string;
  setLanguage: (lang: string) => void;
  availableLanguages: string[];
  onClose: () => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ isAutoPlayEnabled, setAutoPlay, language, setLanguage, availableLanguages, onClose }) => {
  const [profile, setProfile] = useState<any>(null);
  const [isLocalAutoPlayEnabled, setIsLocalAutoPlayEnabled] = useState<boolean>(isAutoPlayEnabled);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + 'account/profile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setProfile(data);
          setIsLocalAutoPlayEnabled(Boolean(data.autoplay));
        })
        .catch(error => {
          console.error('Error fetching profile:', error);
        });
    }
  }, []);

  const handleAutoPlayToggle = async () => {
    try {
      const token = getJwtToken();
      const newStatus = !isLocalAutoPlayEnabled;

      const response = await fetch(reactServer + 'account/settings/autoplay', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: profile.email, autoplayEnabled: newStatus }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsLocalAutoPlayEnabled(newStatus);
      setAutoPlay(newStatus); // Update parent state if needed
    } catch (error) {
      console.error('Error updating AutoPlay status:', error);
    }
  };

  return (
    <div className="settings-menu">
      <div>
        <button className="close-btn" onClick={onClose}>X</button>
        <h3>Instellingen</h3> 
      </div>
      <div className="setting-item">
        <label>
          Automatisch Afspelen
          <input
            type="checkbox"
            checked={isLocalAutoPlayEnabled}
            onChange={handleAutoPlayToggle}
          />
        </label>
      </div>
      <div className="setting-item">
        <label>
          Voertaal pagina
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            {availableLanguages.map(lang => (
              <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
        </label>
      </div>
    </div>
  );
};

export default SettingsMenu;
