import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './tolkchat.css'

// typescript imports
import Eindscherm from './pages/eindegesprek';
import SettingsMenu from './pages/SettingsMenu';
import LanguageSelector from './pages/languageselector';
import ErrorMessage from './notifications/Error';

// langauge mappings
import { allLanguages, TranslateMapping, SpeechToTextMapping, TextToSpeechMapping } from './mappings/microsoft';
import { chatTextLanguage } from './mappings/patientText';

// import the microsoft token
import { getSpeechToken } from './functions/MicrosoftToken'
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

// parse date
import { parseDate } from './functions/parseDate'

// access the server variable
import { getJwtToken } from './auth';
import config from './config';
const reactServer = config.reactServer;

const ChatPage: React.FC = () => {
    
    const [originalMessages, setOriginalMessages] = useState<{ text: string; className: string; mobileClass: string; isEditing: boolean }[]>([]);
    const [leftMessages, setleftMessages] = useState<{ text: string; className: string; mobileClass: string; isEditing: boolean }[]>([]);
    const [rightMessages, setrightMessages] = useState<{ text: string; className: string; mobileClass: string; isEditing: boolean }[]>([]);
    const [allMessages, setallMessages] = useState<{ text: string; className: string; mobileClass: string; isEditing: boolean }[]>([]);
    const [error, setError] = useState('');
    const [conversationId, setConversationId] = useState(1);
    const [currentMessage, setCurrentMessage] = useState('');
    const [currentMessageLeft, setCurrentMessageLeft] = useState('');
    const [currentMessageRight, setCurrentMessageRight] = useState('');
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [profile, setProfile] = useState(null);
    const [doctor, setDoctor] = useState('Dokter');
    const [patient, setPatient] = useState('Patiënt');
    const [fromLanguage, setFromLanguage] = useState('English (United Kingdom)');
    const [toLanguage, setToLanguage] = useState('Dutch (Netherlands)');
    const [isChatStarted, setIsChatStarted] = useState(false);
    const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationType, setConfirmationType] = useState('');
    const [eindeGesprek, setEindeGesprek] = useState(false);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [transcription, setTranscription] = useState<string>('');
    // const recognition = useRef<MediaRecorder | null>(null);
    const navigate = useNavigate();

    // Settings
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isAutoPlayEnabled, setAutoPlay] = useState(false);
    const [language, setLanguage] = useState('Nederlands');
    const availableLanguages = ['Nederlands']; // English later

    const currentDate = new Date().toISOString().split('T')[0]; // dit moet opgehaald worden ipv de tijd van de laptop van de gebruiker zijn

    const [isPatientConsentGiven, setIsPatientConsentGiven] = useState(false);

    // Handler for checkbox change
    const handleCheckboxChange = (e) => {
        setIsPatientConsentGiven(e.target.checked);
    };

    useEffect(() => {
        setShowNavbarAndFooter(false);
    }, []);

    // Get all the data from the user
    useEffect(() => {
        const token = getJwtToken();

        if (token) {
            fetch(reactServer + 'account/profile', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setProfile(data);
                    setDoctor(data.naam)
                    setAutoPlay(data.autoplay)
                })
                .catch(error => {
                    console.error('Error fetching profile:', error);
                });
        }
    }, []);

    const allPatients = profile?.patienten?.map(patient => `${patient.naam} ${patient.surname}`) || [];


    const startChat = () => {
        if (profile) {

            if (profile.subscription[0] === 'lite' && profile.usage >= 5) {
                setError('Maximaal aantal gesprekken gevoerd met uw beginner account. U moet upgraden naar een betaald account.');
                return;
            }

            if (profile.subscription[0] === 'no') {
                setError('U heeft momenteel geen lopend abbonement. U moet upgraden naar een betaald account.');
                return;
            }

            // Check if the current date is not after the subscription end date
            if (profile.subscription[1] && parseDate(currentDate) > parseDate(profile.subscription[1])) {
                setError('Uw abonnement is verlopen. Gelieve uw abonnement te vernieuwen.');
                return;
            }

            setIsChatStarted(true);

            const token = getJwtToken();
            if (token) {

                // First, update the user's usage count
                fetch(reactServer + 'account/update_usage', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: profile.id }) // Include the user ID in the request body
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        // Handle success response if needed
                        console.log('Usage count updated:', data);
                        // Now, proceed to create a new conversation
                    })
                    .catch(error => {
                        console.error('Error updating usage count:', error);
                    });
            }
        }
    };

    const setPatientAndLanguage = (selectedPatient: string) => {
        const foundPatient = profile?.patienten?.find((patient: any) =>
            `${patient.naam} ${patient.surname}` === selectedPatient
        );

        if (foundPatient) {
            setPatient(selectedPatient);
            setFromLanguage(foundPatient.language);
        }
    };

    const handleConfirmation = (type: string) => {
        const confirmationMessages: Record<string, string> = {
            'Einde gesprek': 'Weet je zeker dat je dit gesprek wilt afsluiten?',
            'Technisch probleem': 'Deze functie is nog niet geimplementeerd.',
            'Twee schermen': 'Deze functie is nog niet geimplementeerd.',
        };

        setConfirmationType(type);
        setConfirmationMessage(confirmationMessages[type] || '');
        
        if (type === 'Einde gesprek') {
            setConfirmationModal(true);
        }
    };

    const confirmAction = () => {
        setEindeGesprek(true);
        setConfirmationModal(false);
        setConfirmationType('');
    };


    const sendMessage = async (language: string) => {
        setIsSendingMessage(true);

        if (currentMessage.trim() === '') return;

        const message = {
            text: currentMessage,
            className: language === fromLanguage ? 'message-left' : 'message-right',
            mobileClass: language === fromLanguage ? 'mobile-left' : 'mobile-right',
            isEditing: false
        };

        setCurrentMessage('');
        setCurrentMessageLeft('');
        setCurrentMessageRight('');

        // Define translatedMessage before the conditional
        let translatedMessage = {
            text: '',
            className: '',
            mobileClass: '',
            isEditing: false
        };

        // Translate the message
        if (language === fromLanguage) {
            translatedMessage.text = await translateMessage(message.text, fromLanguage, toLanguage);
            translatedMessage.className = 'message-left';
            translatedMessage.mobileClass = 'mobile-right'
            translatedMessage.isEditing = false;
        } else {
            translatedMessage.text = await translateMessage(message.text, toLanguage, fromLanguage);
            translatedMessage.className = 'message-right';
            translatedMessage.mobileClass = 'mobile-left';
            translatedMessage.isEditing = false;
        }

        setIsSendingMessage(false);
        if (language === fromLanguage) {
            setleftMessages([...leftMessages, message]);
            setrightMessages([...rightMessages, translatedMessage]);
            setOriginalMessages([...originalMessages, message]);
            setallMessages([...allMessages, message, translatedMessage]);
        } else {
            setleftMessages([...leftMessages, translatedMessage]);
            setrightMessages([...rightMessages, message]);
            setOriginalMessages([...originalMessages, message]);
            setallMessages([...allMessages, message, translatedMessage]);
        }

        // Autoplay enabled play the fragment in the other language
        if (profile) {
            if (isAutoPlayEnabled) {
                handleTextToSpeech(translatedMessage)
            }
        }

    };

    useEffect(() => {
    }, [rightMessages]);


    const translateMessage = async (text: string, source_language: string, target_language: string) => {
        const token = getJwtToken();
        try {
            const response = await fetch(reactServer + 'translation/translate', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    text: text,
                    source_language: TranslateMapping[source_language],
                    target_language: TranslateMapping[target_language]
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.translated_text;
        } catch (error) {
            console.error("There was an error translating the text: ", error);
            return null;
        }
    };


    const handleVoiceInput = async (speaker: string) => {
        if (!isRecording) {
            setIsSendingMessage(true);
            setIsRecording(true);
            startSpeechRecognition(speaker);
        } else {
            setIsSendingMessage(false)
            stopRecording();
        }
    };

    const recognizerRef = useRef<sdk.SpeechRecognizer | null>(null);

    const stopRecording = () => {
        stopSpeechRecognition();
        setIsRecording(false);
        console.log('Pressed stop recording.')

    };

    const lastTranslatedMessage = useRef(null);

    const startSpeechRecognition = async (speaker: string) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            if (stream) {
                const token = await getSpeechToken(); // Get your speech token
                const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(token, 'northeurope');
                const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

                let autoDetectSourceLanguageConfig;
                if (speaker === 'doctor') {
                    console.log('Microfoon van de dokter geactiveerd, text zal nu in het Nederlands worden opgenomen.');
                    autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages(["nl-NL"]);
                } else {
                    const detectedLanguage = SpeechToTextMapping[fromLanguage];
                    console.log('Microfoon van de patient geactiveerd, text zal nu worden opgenomen in het', detectedLanguage);
                    autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages([detectedLanguage]);
                }

                const recognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
                recognizerRef.current = recognizer; // Store the recognizer in the ref

                let currentMessageText = '';
                let currentMessageEnding = '';

                recognizer.recognizing = async (s, e) => {
                    console.log('RECOGNIZING:', e.result.text);
                    currentMessageEnding = e.result.text

                    const message = {
                        text: currentMessageText + ' ' + currentMessageEnding,
                        className: speaker === 'doctor' ? 'message-right' : 'message-left',
                        mobileClass: speaker === 'doctor' ? 'mobile-right' : 'mobile-left',
                        isEditing: false
                    };

                    const translatedMessage = {
                        text: speaker === 'doctor' ? await translateMessage(currentMessageText + ' ' + currentMessageEnding, toLanguage, fromLanguage) : await translateMessage(currentMessageText + ' ' + currentMessageEnding, fromLanguage, toLanguage),
                        className: speaker === 'doctor' ? 'message-right' : 'message-left',
                        mobileClass: speaker === 'doctor' ? 'mobile-left' : 'mobile-right',
                        isEditing: false
                    };

                    setIsSendingMessage(false);
                    if (speaker === 'doctor') {
                        setrightMessages([...rightMessages, message]);
                        setleftMessages([...leftMessages, translatedMessage]);
                    } else {
                        setrightMessages([...rightMessages, translatedMessage]);
                        setleftMessages([...leftMessages, message]);
                    }
                    setOriginalMessages([...originalMessages, message]);
                    setallMessages([...allMessages, message, translatedMessage]);
                };

                recognizer.recognized = async (s, e) => {
                    if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                        currentMessageText += ' ' + e.result.text;
                        console.log('RECOGNIZED:', currentMessageText);

                        const message = {
                            text: currentMessageText,
                            className: speaker === 'doctor' ? 'message-right' : 'message-left',
                            mobileClass: speaker === 'doctor' ? 'mobile-right' : 'mobile-left',
                            isEditing: false
                        };

                        const translatedMessage = {
                            text: speaker === 'doctor' ? await translateMessage(currentMessageText, toLanguage, fromLanguage) : await translateMessage(currentMessageText, fromLanguage, toLanguage),
                            className: speaker === 'doctor' ? 'message-right' : 'message-left',
                            mobileClass: speaker === 'doctor' ? 'mobile-left' : 'mobile-right',
                            isEditing: false
                        };

                        lastTranslatedMessage.current = translatedMessage;

                        setIsSendingMessage(false);
                        if (speaker === 'doctor') {
                            setrightMessages([...rightMessages, message]);
                            setleftMessages([...leftMessages, translatedMessage]);
                        } else {
                            setrightMessages([...rightMessages, translatedMessage]);
                            setleftMessages([...leftMessages, message]);
                        }
                        setOriginalMessages([...originalMessages, message]);
                        setallMessages([...allMessages, message, translatedMessage]);
                    } else if (e.result.reason === sdk.ResultReason.NoMatch) {
                        console.log('No speech recognized.');
                    }
                };

                recognizer.canceled = (s, e) => {
                    console.error('Recognition canceled:', e.reason);
                };

                recognizer.sessionStopped = (s, e) => {
                    if (isAutoPlayEnabled) {
                        handleTextToSpeech(lastTranslatedMessage.current)
                    }
                }

                recognizer.startContinuousRecognitionAsync();
            }
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopSpeechRecognition = () => {
        if (recognizerRef.current) {
            recognizerRef.current.stopContinuousRecognitionAsync(() => {
                console.log('Recognition stopped.');
            }, (err) => {
                console.error('Error stopping recognition:', err);
            });
        }
    };


    const handleTextToSpeech = async (message) => {
        try {
            console.log('Starting text to speech.');
            const token = getJwtToken();
            const formData = new FormData();
            console.log(message)
            formData.append('input_text', message.text);
            formData.append('voice', message.mobileClass === 'mobile-right' ? TextToSpeechMapping[toLanguage] : TextToSpeechMapping[fromLanguage]); // Default voice, change if needed
            formData.append('speed', '1'); // Default speed, change if needed

            const response = await fetch(reactServer + '/speech/text_to_speech', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            // Parse the response as a Blob
            const blob = await response.blob();

            // Create a URL for the Blob object
            const audioUrl = URL.createObjectURL(blob);

            // Play the audio
            const audio = new Audio(audioUrl);
            audio.play();

        } catch (error) {
            console.error('Error converting text to speech:', error);
        }
    };


    const rightMessagesRef = useRef<(HTMLDivElement | null)[]>([]);
    const leftMessagesRef = useRef<(HTMLDivElement | null)[]>([]);
    const allMessagesRef = useRef<(HTMLDivElement | null)[]>([]);


    const handleEditMessage = async (index: number, newText: string, side: string | { className: string, mobileClass: string }) => {
        if (side === 'right') {
            const updatedMessages = [...rightMessages];
            updatedMessages[index].text = newText;
            updatedMessages[index].isEditing = false;
            setrightMessages(updatedMessages);

            // Translate to the left side language
            const translatedText = await translateMessage(newText, toLanguage, fromLanguage);
            const updatedLeftMessages = [...leftMessages];
            updatedLeftMessages[index].text = translatedText;
            setleftMessages(updatedLeftMessages);
        } else if (side === 'left') {
            const updatedMessages = [...leftMessages];
            updatedMessages[index].text = newText;
            updatedMessages[index].isEditing = false;
            setleftMessages(updatedMessages);

            // Translate to the right side language
            const translatedText = await translateMessage(newText, fromLanguage, toLanguage);
            const updatedRightMessages = [...rightMessages];
            updatedRightMessages[index].text = translatedText;
            setrightMessages(updatedRightMessages);
        } else if (typeof side !== 'string') {
            if ((side.mobileClass === 'mobile-left' && side.className === 'message-left')) {
                const updatedMessages = [...allMessages];
                updatedMessages[index].text = newText;
                updatedMessages[index].isEditing = false;
                setallMessages(updatedMessages);
                // Translate to the right side language
                const translatedText = await translateMessage(newText, toLanguage, fromLanguage);
                const updatedAllMessages = [...allMessages];
                updatedAllMessages[index + 1].text = translatedText;
                setallMessages(updatedAllMessages);
            } else if ((side.mobileClass === 'mobile-left' && side.className === 'message-right')) {
                const updatedMessages = [...allMessages];
                updatedMessages[index].text = newText;
                updatedMessages[index].isEditing = false;
                setallMessages(updatedMessages);

                // Translate to the right side language
                const translatedText = await translateMessage(newText, fromLanguage, toLanguage);
                const updatedAllMessages = [...allMessages];
                updatedAllMessages[index - 1].text = translatedText;
                setallMessages(updatedAllMessages);
            } else if ((side.mobileClass === 'mobile-right' && side.className === 'message-right')) {
                const updatedMessages = [...allMessages];
                updatedMessages[index].text = newText;
                updatedMessages[index].isEditing = false;
                setallMessages(updatedMessages);

                // Translate to the right side language
                const translatedText = await translateMessage(newText, toLanguage, fromLanguage);
                const updatedAllMessages = [...allMessages];
                updatedAllMessages[index + 1].text = translatedText;
                setallMessages(updatedAllMessages);
            } else if ((side.mobileClass === 'mobile-right' && side.className === 'message-left')) {
                const updatedMessages = [...allMessages];
                updatedMessages[index].text = newText;
                updatedMessages[index].isEditing = false;
                setallMessages(updatedMessages);

                // Translate to the right side language
                const translatedText = await translateMessage(newText, fromLanguage, toLanguage);
                const updatedAllMessages = [...allMessages];
                updatedAllMessages[index - 1].text = translatedText;
                setallMessages(updatedAllMessages);
            }
        }
    };

    const toggleEditing = (index: number, side: string | { className: string, mobileClass: string }) => {
        if (side === 'right') {
            const updatedMessages = [...rightMessages];
            updatedMessages[index].isEditing = !updatedMessages[index].isEditing; // Toggle editing state
            setrightMessages(updatedMessages);
        } else if (side == 'left') {
            const updatedMessages = [...leftMessages];
            updatedMessages[index].isEditing = !updatedMessages[index].isEditing; // Toggle editing state
            setleftMessages(updatedMessages);
        } else {
            const updatedMessages = [...allMessages];
            updatedMessages[index].isEditing = !updatedMessages[index].isEditing; // Toggle editing state
            setallMessages(updatedMessages);
        }
    };

    const handleClick = (index: number, event: React.MouseEvent, side: string | { className: string, mobileClass: string }) => {
        const isInput = (event.target as HTMLElement).tagName === 'TEXTAREA';
        if (!isInput) {
            toggleEditing(index, side);
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLTextAreaElement>, side: string | { className: string, mobileClass: string }) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior (like adding a new line)
            if (side == 'right') {
                handleEditMessage(index, rightMessages[index].text, side);
            }
            else if (side == 'left') {
                handleEditMessage(index, leftMessages[index].text, side);
            } else {
                handleEditMessage(index, allMessages[index].text, side)
            }

        }
    };

    const handleCloseError = () => {
        setError('');
      };


    return (
        <div className="chat-page">

            {/* Settings */}
            <div className="settings-icon" onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
                <img className='settings-icon-img' src="/settings-icon.png" alt="Settings" />
            </div>

            {/* Conditionally render the SettingsMenu */}
            {isSettingsOpen && (
                <SettingsMenu
                    isAutoPlayEnabled={isAutoPlayEnabled}
                    setAutoPlay={setAutoPlay}
                    language={language}
                    setLanguage={setLanguage}
                    availableLanguages={availableLanguages}
                    onClose={() => setIsSettingsOpen(false)}
                />
            )}

            {/* Starting page of the tolkchat */}
            {profile && !eindeGesprek && (<div>
                {!isChatStarted ? (
                    <div className="chat-setup">
                        <img src='./TolkChatLogo.png' alt="Logo" className="tolkchat-logo tolkchat-logo-mobile" />
                        <h1 className='title'>Welkom, {profile.naam}</h1>
                        <div>
                            <p>Selecteer eventueel een Patiënt om mee te chatten:</p>
                            <div className="dropdown-container">
                                <select
                                    className='selector'
                                    value={patient}
                                    onChange={(e) => setPatientAndLanguage(e.target.value)}
                                >
                                    <option value="">Selecteer Patiënt</option>
                                    {allPatients.map(patient => (
                                        <option key={patient} value={patient}>{patient}</option>
                                    ))}
                                </select>
                                <button className="start-button patient-button" onClick={() => navigate('../domein')}>
                                    Patiënt Toevoegen
                                </button>
                            </div>
                        </div>

                        <div className="checkbox-container">
                            <label htmlFor="agreement-checkbox">
                                Patiënt gaat akkoord met het gebruik van de Tolkchat
                            </label>
                            <input
                                type="checkbox"
                                id="agreement-checkbox"
                                checked={isPatientConsentGiven}
                                onChange={handleCheckboxChange}
                            />

                        </div>

                        <button
                            className="start-button start"
                            onClick={startChat}
                            disabled={!isPatientConsentGiven}
                        >
                            Start TolkChat
                        </button>

                        {error && <ErrorMessage message={error} onClose={handleCloseError} />}
                        <div className='tolkchat-space'></div>
                    </div>
                ) : (
                    <div>

                        {/* Patient info and doctor info */}
                        <div className="user-info">
                            <div className='mobile-tolkchat-header'>
                                <img src='./TolkChatLogo.png' alt="Logo" className="tolkchat-header-image" />
                            </div>
                            <div className="patient-info mobile-hidden">
                                <p className='nametext'>{patient}</p>
                                <div className='patient-language'>
                                    <p className='languagetext'>Language: </p>
                                    <LanguageSelector
                                        allLanguages={allLanguages}
                                        fromLanguage={fromLanguage}
                                        setFromLanguage={setFromLanguage}
                                    />
                                </div>
                            </div>
                            <div className="doctor-info mobile-hidden">
                                <p className='nametext-mobile nametext'>{profile.naam}</p>
                                <div>
                                    <p className='languagetext'>Language: Nederlands</p>
                                    <p className='languagetext-mobile nametext'>Nederlands</p>
                                </div>
                            </div>
                        </div>

                        {/* Desktop Chat Interface */}
                        <div className="chat-interface chat-desktop">
                            <div className="chat-screen left-chat">
                                {leftMessages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={message.className}
                                        ref={(el) => (leftMessagesRef.current[index] = el)} // Set ref for the message container
                                        onClick={(event) => handleClick(index, event, 'left')} // Use the new handleClick function
                                    >
                                        {message.isEditing ? (
                                            <textarea
                                                className={message.className === 'message-left' ? 'editor-left' : 'editor-right'}
                                                value={message.text}
                                                spellCheck={false}
                                                onChange={(e) => {
                                                    const updatedMessages = [...leftMessages];
                                                    updatedMessages[index].text = e.target.value; // Update message text on change
                                                    setleftMessages(updatedMessages);
                                                }}
                                                onBlur={() => handleEditMessage(index, message.text, 'left')} // Confirm change on blur
                                                onKeyDown={(e) => handleKeyDown(index, e, 'left')} // Handle Enter key
                                                style={{
                                                    width: leftMessagesRef.current[index]?.offsetWidth - 41, // Set width to the same as message-left
                                                    height: leftMessagesRef.current[index]?.offsetHeight - 15, // Set height to the same as message-left
                                                }}
                                            />
                                        ) : (
                                            <span>{message.text}</span> // Display message text
                                        )}
                                        <img
                                            className="speaker"
                                            src="/speaker.png"
                                            alt="speaker"
                                            onClick={() => handleTextToSpeech(message)}
                                        />
                                    </div>
                                ))}
                                {isSendingMessage && (
                                    <div className="message-left">
                                        <span className="message-placeholder">. . .</span>
                                    </div>
                                )}
                            </div>
                            <div className="chat-screen right-chat">
                                {rightMessages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={message.className}
                                        ref={(el) => (rightMessagesRef.current[index] = el)} // Set ref for the message container
                                        onClick={(event) => handleClick(index, event, 'right')} // Use the new handleClick function
                                    >
                                        {message.isEditing ? (
                                            <textarea
                                                className={message.className === 'message-right' ? 'editor-right' : 'editor-left'}
                                                value={message.text}
                                                spellCheck={false}
                                                onChange={(e) => {
                                                    const updatedMessages = [...rightMessages];
                                                    updatedMessages[index].text = e.target.value; // Update message text on change
                                                    setrightMessages(updatedMessages);
                                                }}
                                                onBlur={() => handleEditMessage(index, message.text, 'right')} // Confirm change on blur
                                                onKeyDown={(e) => handleKeyDown(index, e, 'right')} // Handle Enter key
                                                style={{
                                                    width: rightMessagesRef.current[index]?.offsetWidth - 46, // Set width to the same as message-right
                                                    height: rightMessagesRef.current[index]?.offsetHeight - 15, // Set height to the same as message-right
                                                }}
                                            />
                                        ) : (
                                            <span>{message.text}</span> // Display message text
                                        )}
                                        <img
                                            className="speaker"
                                            src="/speaker.png"
                                            alt="speaker"
                                            onClick={() => handleTextToSpeech(message)}
                                        />
                                    </div>
                                ))}
                                {isSendingMessage && (
                                    <div className="message-right">
                                        <span className="message-placeholder">. . .</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Mobile Chat Interface*/}
                        <div className="chat-interface chat-mobile">
                            <div className="chat-screen left-chat">
                                {allMessages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={message.className + ' ' + message.mobileClass}
                                        ref={(el) => (allMessagesRef.current[index] = el)} // Set ref for the message container
                                        onClick={(event) => handleClick(index, event, 'all')} // Handle click for editing
                                    >
                                        {message.isEditing ? (
                                            <textarea
                                                className={'editor-mobile'}
                                                value={message.text}
                                                spellCheck={false}
                                                onChange={(e) => {
                                                    const updatedMessages = [...allMessages];
                                                    updatedMessages[index].text = e.target.value; // Update message text on change
                                                    setallMessages(updatedMessages);
                                                }}
                                                onBlur={() => handleEditMessage(index, message.text, { className: message.className, mobileClass: message.mobileClass })} // Confirm change on blur
                                                onKeyDown={(e) => handleKeyDown(index, e, { className: message.className, mobileClass: message.mobileClass })} // Handle Enter key
                                                style={{
                                                    width: allMessagesRef.current[index]?.offsetWidth - 45, // Set width to the same as message-left
                                                    height: allMessagesRef.current[index]?.offsetHeight - 19, // Set height to the same as message-left
                                                }}
                                            />
                                        ) : (
                                            <span>{message.text}</span> // Display message text
                                        )}

                                        {Object.keys(TextToSpeechMapping).includes(fromLanguage) ? (
                                            <img
                                                className="speaker"
                                                src="/speaker.png"
                                                alt="speaker"
                                                onClick={() => handleTextToSpeech(message)}
                                            />
                                        ) : (
                                            <span>&nbsp;</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* Desktop Text Input */}
                        <div className="input-container input-desktop">

                            <textarea
                                className="input-message"
                                placeholder={chatTextLanguage[fromLanguage]}
                                value={currentMessageLeft}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageLeft(e.target.value) }}
                                style={{ width: '38%', height: '60px', fontSize: '16px', marginRight: '30px' }}
                            />
                            <textarea
                                className="input-message"
                                placeholder="Start het gesprek met uw patiënt hier..."
                                value={currentMessageRight}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageRight(e.target.value) }}
                                style={{ width: '38%', height: '60px', fontSize: '16px', marginLeft: '30px' }}
                            />
                        </div>

                        {/* Mobile Text Input */}
                        <div className="input-container input-mobile">

                            <textarea
                                className="input-message"
                                placeholder={'Start het gesprek met uw patiënt hier...'}
                                value={currentMessageLeft}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageLeft(e.target.value) }}
                                style={{ width: '92%', height: '60px', fontSize: '16px' }}
                            />
                        </div>

                        {/* Desktop Sending Buttons */}
                        <div className="alignment-buttons mobile-hidden">
                            {transcription}
                            <div>
                                <img
                                    className={`microphone ${isRecording ? "recording" : ""}`}
                                    src={isRecording ? "/recording.png" : "/microphone.png"}
                                    alt="microphone"
                                    onClick={() => handleVoiceInput('patient')}
                                />

                                <button className="send-button left-button" onClick={() => sendMessage(fromLanguage)}>Versturen</button>
                            </div>
                            <div>
                                <button className="send-button right-button" onClick={() => sendMessage(toLanguage)}>{doctor}</button>
                                <img
                                    className={`microphone ${isRecording ? "recording" : ""}`}
                                    src={isRecording ? "/recording.png" : "/microphone.png"}
                                    alt="microphone"
                                    onClick={() => handleVoiceInput('doctor')}
                                />
                            </div>
                        </div>

                        {/* Mobile Sending Buttons */}
                        <div className="alignment-buttons desktop-hidden">
                            {transcription}
                            <div className="input-container">
                                <div className="button-row">
                                    <img
                                        className={`microphone ${isRecording ? "recording" : ""}`}
                                        src={isRecording ? "/recording.png" : "/microphone.png"}
                                        alt="microphone"
                                        onClick={() => handleVoiceInput('patient')}
                                    />

                                    <button className="send-button left-button" onClick={() => sendMessage(fromLanguage)}>{patient} Knop</button>
                                </div>
                                <div className='mobile-selector'>
                                    <LanguageSelector
                                        allLanguages={allLanguages}
                                        fromLanguage={fromLanguage}
                                        setFromLanguage={setFromLanguage}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="button-row">
                                    <button className="send-button right-button" onClick={() => sendMessage(toLanguage)}>{doctor}</button>
                                    <img
                                        className={`microphone ${isRecording ? "recording" : ""}`}
                                        src={isRecording ? "/recording.png" : "/microphone.png"}
                                        alt="microphone"
                                        onClick={() => handleVoiceInput('doctor')}
                                    />
                                </div>
                                <div className='languagetext-mobile-box'>
                                    <p className='languagetext-mobile nametext'>Nederlands</p>
                                    <div className='mobile-space'></div>
                                </div>
                            </div>
                        </div>


                        <button className="samenvatten-button" onClick={() => handleConfirmation('Twee schermen')}>
                            Twee schermen
                        </button>

                        <button className="samenvatten-button" onClick={() => handleConfirmation('Einde gesprek')}>
                            Einde gesprek
                        </button>

                        <button className="samenvatten-button technisch" onClick={() => handleConfirmation('Technisch probleem')}>
                            Technisch probleem
                        </button>

                        <div className='informed-mistakes'>
                            Tolkchat kan fouten maken. Controleer belangrijke informatie.
                        </div>
                        
                        <p>{confirmationMessage}</p>

                        {/* Confirmation Page */}
                        {confirmationModal && (
                            <div className="confirmation-modal">
                                
                                <button className="samenvatten-button" onClick={confirmAction}>Bevestigen</button>
                                <button className="samenvatten-button" onClick={() => setConfirmationModal(false)}>Annuleren</button>
                            </div>
                        )}
                    </div>
                )}
            </div>)}

            {/* Final Screen */}
            {profile && eindeGesprek &&
                <div>
                    <Eindscherm
                        conversationId={conversationId.toString()}
                        transcript={originalMessages}
                        transcript_dutch={rightMessages}
                        transcript_foreign={leftMessages}
                    />
                </div>}
        </div>
    );
};

export default ChatPage;
