// abonnement.tsx
import './abbonement.css';
import React, { useState, useEffect } from 'react';
import checkingGif from '../images/check.gif'; // Optional: A loading indicator or similar image
import { getJwtToken } from '../auth'; // Import JWT token function
import config from '../config'; // Import configuration
const reactServer = config.reactServer;

const Abonnement: React.FC = () => {
  const [profile, setProfile] = useState<any>(null); // Define the profile state
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState<string | null>(null); // Error state
  const [actionMessage, setActionMessage] = useState<string>(''); // Message for actions

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(`${reactServer}account/profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setProfile(data);
        })
        .catch(error => {
          setError(`Error fetching profile: ${error.message}`);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    }
  }, []);

  // Function to cancel subscription
  const cancelSubscription = async () => {
    if (profile?.subscription[0] !== 'busy') {
      setActionMessage('Je kunt een niet-druk abonnement niet annuleren.');
      return;
    }

    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/cancel-subscription`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setActionMessage('Je abonnement is geannuleerd.');
      // Optionally, fetch updated profile data here
    } else {
      setActionMessage('Fout bij het annuleren van het abonnement.');
    }
  };

  // Function to upgrade subscription
  const upgradeSubscription = async () => {
    setActionMessage('Je account is geüpgraded naar een Professioneel account! Hier is je downloadlink voor het contract: [Download Contract](#)'); // Mock link
    // You can implement actual API call for upgrading the subscription here
  };

  // Function to pause subscription
  const pauseSubscription = async () => {
    if (profile?.subscription[0] !== 'busy') {
      setActionMessage('Je kunt een niet-druk abonnement niet pauzeren.');
      return;
    }

    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/pause-subscription`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setActionMessage('Je abonnement is gepauzeerd.');
      // Optionally, fetch updated profile data here
    } else {
      setActionMessage('Fout bij het pauzeren van het abonnement.');
    }
  };

  // Loading and error handling
  if (loading) {
    return (
      <div className="loading-container">
        <img src={checkingGif} alt="Loading..." />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className='abbonement-page'>
      <div className="abonnement-page-container">
        <h2 className="abonnement-title">Abonnement Details</h2>

        {profile && (
          <div className="abonnement-details">
            <p className="abonnement-info">
              <strong>Huidige Abonnementstype:</strong>
              {profile.subscription[0] === 'pro' ? ' Professioneel' : profile.subscription[0] === 'lite' ? ' Proefversie' : ' Geen Abonnement'}
            </p>
            <p className="abonnement-info">
              <strong>Abonnement geldig tot:</strong> {new Date(profile.subscription[1]).toLocaleDateString()}
            </p>

            {/* Display cancellation message if the subscription is from an organization */}
            {profile.subscription[3] ? (
              <p className="organization-message">Neem contact op met uw organisatie om uw abonnement te stoppen.</p>
            ) : (
              <div className="user-actions">
                {profile.subscription[0] === 'pro' && (
                  <>
                    <button className="action-button cancel-button" onClick={cancelSubscription}>Annuleer Abonnement</button>
                    <button className="action-button pause-button" onClick={pauseSubscription}>Pauzeer Abonnement</button>
                  </>
                )}
                {profile.subscription[0] === 'lite' && (
                  <button className="action-button upgrade-button" onClick={upgradeSubscription}>Upgrade naar Professioneel</button>
                )}
              </div>
            )}
          </div>
        )}

        {actionMessage && <p className="action-message">{actionMessage}</p>}
      </div>
      <div className='spacer'></div>
    </div>
  );
};

export default Abonnement;
