// Function to parse dates in the format YYYY-MM-DD or DD-MM-YYYY
export function parseDate(dateString) {
    let parts;

    // If date is in YYYY-MM-DD format
    if (dateString.includes('-') && dateString.indexOf('-') === 4) {
        return new Date(dateString);
    }

    // If date is in DD-MM-YYYY format
    if (dateString.includes('-')) {
        parts = dateString.split('-');
        // Return date as YYYY-MM-DD
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    }

    // Fallback in case of unrecognized format
    return new Date(dateString);
}
