import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';

import LogoAnimation from './logoAnimation';
import Error from './notifications/Error'
import Neutral from './notifications/Neutral';
import TolkPage from './tolkchat';
// import TranslationPage from './translation';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import WaaromPage from './pages/waarom';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import RegisterOrganisation from './pages/registerorg';
import History from './pages/history';
import Domein from './pages/profiel';
import Abonnementen from './pages/abbonement';
import Contract from './pages/contract';
import ProfielOverzicht from './pages/profieloverzicht';
import FAQ from './pages/faq';
import Ontwikkeling from './pages/ontwikkeling';
import DemoPilot from './pages/demopilot';
import Feedback from './pages/contactfeedback';
import ChatPage from './pages/qrchat';
import WachtwoordVergeten from './pages/wachtwoordVergeten';
import ResetPassword from './pages/wachtwoordNieuw';
import QRCodePage from './pages/qrcode';
import Patients from './pages/patienten'
import MijnBedrijf from './pages/mijnbedrijf';
import MijnBedrijfOverzicht from './pages/mijnbedrijfoverzicht';

import './App.css'; // Import your main App stylesheet here

import { getJwtToken } from './auth';
import config from './config';
import TranslationPage from './pages/translation';
import ContactFeedbackPage from './pages/contactfeedback';
import useConnectivityStatus from './functions/useConnectivityStatus';

const reactServer = config.reactServer;

const App: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [isInTolkChat, setIsInTolkChat] = useState(false); // New state to track if user is in Tolk Chat page

  const [currentPath, setCurrentPath] = useState<string>(window.location.pathname);

  const { isOnline, isServerReachable } = useConnectivityStatus(); // Use the custom hook
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [neutralMessage, setNeutralMessage] = useState<string | null>(null);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  // Get all the data from the user
  const [profile, setProfile] = useState({ is_admin: false, naam: false, organisation: ['', 0, 0, 0] });

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + 'account/profile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`)
            const errorMsg = 'U bent momenteel niet ingelogd op het portaal.';
            setNeutralMessage(errorMsg);
          }
          return response.json();
        })
        .then(data => {
          setProfile(data);
        })
        .catch(error => {
          console.error('Error fetching profile:', error);
        });
    }
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentPath.includes('/tolkchat')) {
      setIsInTolkChat(true);
    } else {
      setIsInTolkChat(false);
    }
  }, [currentPath]);


  const handleNavigation = (path: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isInTolkChat) {
      const confirmNavigation = window.confirm('Uw gespreksdata zal verloren gaan. Weet u zeker dat u het huidige gesprek wilt afsluiten?');
      if (!confirmNavigation) {
        event.preventDefault(); // Prevents the default behavior of the link
      } else {
        setCurrentPath(path);
      }
    } else {
      setCurrentPath(path);
    }
  };


  const handleCloseError = () => {
    setErrorMessage(null);
  };

  const handleCloseNeutral = () => {
    setNeutralMessage(null);
  };


  useEffect(() => {
    if (!isOnline) {
      setErrorMessage("Geen internet. Check uw verbinding");
    } else if (!isServerReachable) {
      setErrorMessage("Kan de server niet bereiken. Probeer het later opnieuw.");
    } else {
      setErrorMessage(null);
    }
  }, [isOnline, isServerReachable]);

  return (
    <div>
      {animationFinished === false && (<LogoAnimation onAnimationEnd={handleAnimationEnd} />)}
      {animationFinished && (
        <Router>
          <div>
            <nav className={`navigation ${scrolled ? 'scrolled' : ''}`}>
              {profile.is_admin ? (
                <Link className="nav-link" to="/profieloverzicht" onClick={(e) => handleNavigation('/profieloverzicht', e)}>Profieloverzicht</Link>
              ) : (
                <Link className="nav-link" to="https://wellcomtranslation.com/" target="_blank" rel="noopener noreferrer" onClick={(e) => handleNavigation('/waarom', e)}>Waarom Wellcom?</Link>
              )}
              <Link className="nav-link" to={profile.naam ? "/feedback" : "/contact"} onClick={(e) => handleNavigation(profile.naam ? "/feedback" : "/contact", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? "Feedback" : "Contact"}</Link>
              <Link className="nav-link show-on-mobile-only" to={"/tolkchat"}>Tolk Chat</Link>
              <Link className="nav-link" to={profile.organisation && profile.organisation[3] ? "/mijnbedrijf" : "/abonnement"} onClick={(e) => handleNavigation(profile.organisation && profile.organisation[3] ? '/mijnbedrijf' : '/abonnement', e)}>{profile.organisation && profile.organisation[3] ? profile.organisation[0] : "Abonnement"}</Link>
              <Link to={profile.naam ? "/tolkchat" : "/login"}  onClick={(e) => handleNavigation(profile.naam ? "/tolkchat" : "/login", e)}>
                <img src='./logo.png' alt="Logo" className="nav-logo" />
              </Link>
              {profile.is_admin ? (
                <Link className="nav-link" to="/bedrijfoverzicht" onClick={(e) => handleNavigation('/bedrijfoverzicht', e)}>Bedrijfoverzicht</Link>
              ) : (
                <Link className="nav-link" to={profile.naam ? "/tolkchat" : "/login"} onClick={(e) => handleNavigation('/tolkchat', e)}>Tolk Chat</Link>
              )}
              
              <Link className="nav-link" to={profile.naam ? "/domein" : "/ontwikkeling"} onClick={(e) => handleNavigation(profile.naam ? "/domein" : "/ontwikkeling", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? 'Profiel' : "Ontwikkeling"}</Link>
              <Link className="nav-link show-on-mobile" to={profile.naam ? "/domein" : "/login"} onClick={(e) => handleNavigation(profile.naam ? "/domein" : "/login", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? (isHovered ? profile.naam : profile.naam) : "Login"}</Link>
            </nav>

            <div className='main-content'>
              <Routes>
                <Route path="/" element={profile.naam ? <TolkPage /> : <LoginPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/waarom" element={<WaaromPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/register-organisation" element={<RegisterOrganisation />} />
                <Route path="/tolkchat" element={<TolkPage />} />
                <Route path="/history" element={<History />} />
                <Route path="/domein" element={<Domein />} />
                <Route path="/abonnement" element={<Abonnementen />} />
                <Route path="/contract" element={<Contract />} />
                <Route path='/profieloverzicht' element={<ProfielOverzicht />} />
                <Route path='/translate' element={<TranslationPage />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/ontwikkeling' element={<Ontwikkeling />} />
                <Route path='/demopilot' element={<DemoPilot />} />
                <Route path='/feedback' element={<Feedback />} />
                <Route path="/chat/:conversationId" element={<ChatPage />} />
                <Route path="/new_chat/:conversationId" element={<ChatPage />} />
                <Route path="/wachtwoord-vergeten" element={<WachtwoordVergeten />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/mijnbedrijf" element={<MijnBedrijf />} />
                <Route path="/bedrijfoverzicht" element={<MijnBedrijfOverzicht />} />
                {/* <Route path="/patientenoverzicht" element={<Patients />} /> */}
              </Routes>
            </div>
          </div>

          <footer className="footer">
            <div className="social-links">
              <a href="https://www.linkedin.com/company/wellcom-translation/" target="_blank" rel="noopener noreferrer">
                <img src="./linkedin-logo.png" alt="LinkedIn" />
              </a>
              <a href="https://www.wellcom-translation.nl" target="_blank" rel="noopener noreferrer">
                <img src="./globe-logo.png" alt="Instagram" />
              </a>
            </div>
            <div className="additional-links">
              <Link to="/faq">FAQ</Link>
              <Link to="/ontwikkeling">Ontwikkeling</Link>
              <a href="/./bestanden/demopilot-handleiding.pdf" download >Demo Pilot</a>
              <a href="/./bestanden/privacy-statement.pdf" download >Privacy Statement</a>
            </div>
            <div className="copyright">
              {new Date().getFullYear()} Wellcom. all rights reserved.
            </div>
          </footer>

          {errorMessage && <Error message={errorMessage} onClose={handleCloseError} />}
          {neutralMessage && <Neutral message={neutralMessage} onClose={handleCloseNeutral} />}
        </Router>
      )}
    </div>
  );
};

export default App;
