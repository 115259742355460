// src/components/Succes.tsx
import React, { useEffect, useState } from 'react';
import './Succes.css';

interface SuccesProps {
  message: string;
  onClose: () => void;
}

const Succes: React.FC<SuccesProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="Succes-container">
      <div className="Succes-content">
        <span>{message}</span>
        <button className="Succes-close" onClick={onClose}>✖</button>
      </div>
    </div>
  );
};

export default Succes;
