export const chatTextLanguage = {
    'Afrikaans (South Africa)':"U kunt hier typen...",
    'Albanian (Albania)':"Ju mund të shkruani këtu...",
    'Amharic (Ethiopia)':"እዚህ መጻፍ ይችላሉ...",
    'Arabic (United Arab Emirates)':"...يمكنك الكتابة هنا",
    'Arabic (Bahrain)':"...يمكنك الكتابة هنا",
    'Arabic (Algeria)':"...يمكنك الكتابة هنا",
    'Arabic (Egypt)':"...يمكنك الكتابة هنا",
    'Arabic (Israel)':"...يمكنك الكتابة هنا",
    'Arabic (Iraq)':"...يمكنك الكتابة هنا",
    'Arabic (Jordan)':"...يمكنك الكتابة هنا",
    'Arabic (Kuwait)':"...يمكنك الكتابة هنا",
    'Arabic (Lebanon)':"...يمكنك الكتابة هنا",
    'Arabic (Libya)':"...يمكنك الكتابة هنا",
    'Arabic (Morocco)':"...يمكنك الكتابة هنا",
    'Arabic (Oman)':"...يمكنك الكتابة هنا",
    'Arabic (Palestinian Authority)':"...يمكنك الكتابة هنا",
    'Arabic (Qatar)':"...يمكنك الكتابة هنا",
    'Arabic (Saudi Arabia)':"...يمكنك الكتابة هنا",
    'Arabic (Syria)':"...يمكنك الكتابة هنا",
    'Arabic (Tunisia)':"...يمكنك الكتابة هنا",
    'Arabic (Yemen)':"...يمكنك الكتابة هنا",
    'Armenian (Armenia)':"Կարող եք այստեղ գրել...",
    'Azerbaijani (Latin, Azerbaijan)':"Burada yaza bilərsiniz...",
    'Bengali (India)':"আপনি এখানে টাইপ করতে পারেন...",
    'Bashkir':"Бында яҙа алаһығыҙ...",
    'Basque':"Hemen idatzi dezakezu...",
    'Bhojpuri':"अहां इहाँ टाइप कर सकेनी...",
    'Bodo':"बिजानेनाय गोसोदै...",
    'Bosnian (Bosnia and Herzegovina)':"Ovdje možete pisati...",
    'Bulgarian (Bulgaria)':"Можете да пишете тук...",
    'Cantonese (Traditional)':"您可以在這裡輸入...",
    'Catalan':"Podeu escriure aquí...",
    'Chinese (Literary)':"你可以在这里打字...",
    'Chinese (Wu, Simplified)':"您可以在此键入...",
    'Chinese (Cantonese, Simplified)':"你可以在这里打字...",
    'Chinese (Mandarin, Simplified)':"你可以在这里打字...",
    'Chinese (Jilu Mandarin, Simplified)':"您可以在此键入...",
    'Chinese (Southwestern Mandarin, Simplified)':"您可以在此键入...",
    'Chinese (Cantonese, Traditional)':"您可以在這裡輸入...",
    'Chinese (Taiwanese Mandarin, Traditional)':"您可以在這裡打字...",
    'chiShona':"Unogona kunyora pano...",
    'Croatian (Croatia)':"Ovdje možete pisati...",
    'Czech (Czechia)':"Zde můžete psát...",
    'Danish (Denmark)':"Du kan skrive her...",
    'Dari':"شما می‌توانید اینجا بنویسید...",
    'Dutch (Netherlands)':"U kunt hier typen...",
    'Dutch (Belgium)':"U kunt hier typen...",
    'English (Australia)':"You can type here...",
    'English (Canada)':"You can type here...",
    'English (United Kingdom)':"You can type here...",
    'English (Ghana)':"You can type here...",
    'English (Hong Kong SAR)':"You can type here...",
    'English (Ireland)':"You can type here...",
    'English (India)':"You can type here...",
    'English (Kenya)':"You can type here...",
    'English (Nigeria)':"You can type here...",
    'English (New Zealand)':"You can type here...",
    'English (Philippines)':"You can type here...",
    'English (Singapore)':"You can type here...",
    'English (Tanzania)':"You can type here...",
    'English (United States)':"You can type here...",
    'English (South Africa)':"You can type here...",
    'Estonian (Estonia)':"Siia saate kirjutada...",
    'Faroese':"Tú kanst skriva her...",
    'Fijian':"O rawa ni vola eke...",
    'Filipino (Philippines)':"Maaari kang mag-type dito...",
    'Finnish':"Voit kirjoittaa tähän...",
    'French (Belgium)':"Vous pouvez taper ici...",
    'French (Canada)':"Vous pouvez taper ici...",
    'French (Switzerland)':"Vous pouvez taper ici...",
    'French (France)':"Vous pouvez taper ici...",
    'Galician':"Pode escribir aquí...",
    'Georgian (Georgia)':"აქ შეგიძლიათ აკრიფოთ...",
    'German (Germany)':"Sie können hier tippen...",
    'German (Austria)':"Sie können hier tippen...",
    'German (Switzerland)':"Sie können hier tippen...",
    'Greek (Greece)':"Μπορείτε να πληκτρολογήσετε εδώ...",
    'Haitian Creole':"Ou ka tape isit la...",
    'Hausa':"Za ka iya rubuta a nan...",
    'Hebrew (Israel)':"...אתה יכול להקליד כאן",
    'Hindi (India)':"आप यहां टाइप कर सकते हैं...",
    'Hungarian (Hungary)':"Itt írhat...",
    'Icelandic (Iceland)':"Þú getur slegið hér inn...",
    'Igbo':"Ị nwere ike ide ebe a...",
    'Indonesian (Indonesia)':"Anda bisa mengetik di sini...",
    'Inuinnaqtun':"Takujummaat sua...",
    'Irish (Ireland)':"Is féidir leat clóscríobh anseo...",
    'Italian':"Puoi digitare qui...",
    'Italian (Switzerland)':"Puoi digitare qui...",
    'Italian (Italy)':"Puoi digitare qui...",
    'Japanese (Japan)':"ここに入力できます...",
    'Kannada (India)':"ನೀವು ಇಲ್ಲಿ ಟೈಪ್ ಮಾಡಬಹುದು...",
    'Kashmiri':"...تۄپۍ ایتھۄ کرئن"
    // More translations follow
};
