// src/hooks/useConnectivityStatus.ts
import { useState, useEffect } from 'react';
import config from '../config';

const useConnectivityStatus = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [isServerReachable, setIsServerReachable] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const response = await fetch(`${config.reactServer}/ping`);
        if (response.ok) {
          setIsServerReachable(true);
        } else {
          setIsServerReachable(false);
        }
      } catch (error) {
        setIsServerReachable(false);
      }
    };

    const interval = setInterval(checkServerStatus, 30000); // Check every 30 seconds
    checkServerStatus();

    return () => clearInterval(interval);
  }, []);

  return { isOnline, isServerReachable };
};

export default useConnectivityStatus;
