// src/views/ontwikkeling.tsx

import React from 'react';
import './infopages.css'

const InfoPage: React.FC = () => {
    return (
        <div className="ontwikkeling-page">
            <h1 className="title">Ontwikkelings Updates</h1>
            {/* <div>Op deze pagina zullen de laatste updates aan het platform worden bijgehouden. Uiteraard zijn wij altijd bezig met de verdere ontwikkeling van ons platform.</div> */}

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.15 (04-10-2024): ▶️</h2>
                <p className="reason-description">
                    Wat zit er allemaal in de nieuwe update:<br></br>
                    1. De optie tot automatisch afspelen werkt nu in de Tolkchat, probeer dit vooral eens!<br></br>
                    2. Wachtwoorden kun je nu door op het oogje te klikken bekijken.<br></br>
                    3. Verschillende veranderingen van de frontend look.<br></br>
                    4. Zorgmedewerkers kunnen een gratis testaccount maken voor 2 weken en 5 uses van de tolkchat.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.14 (27-09-2024): 🧠</h2>
                <p className="reason-description">
                    De Real Realtime Update:<br></br>
                    1. Realtime transcriptie is functioneel en werkt goed.<br></br>
                    2. Realtime vertaling is functioneel en werkt goed.<br></br>
                    3. Coole functie toegevoegd waarmee je berichten kunt bewerken en ze ook kunt vertalen.<br></br>
                    Mobiele compatibiliteit:<br></br>
                    - Mobiele Tolkchat ziet er beter uit dan ooit.<br></br>
                    Account verwijderen:<br></br>
                    - We vinden het jammer als je ons wilt verlaten en willen je graag houden, maar nu is er in ieder geval een optie om die fout te maken.<br></br>
                    Bugfixes:<br></br>
                    - Diverse bugfixes en optimalisaties geïmplementeerd.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.13 (07-09-2024): ✅</h2>
                <p className="reason-description">
                    Functies:<br></br>
                    1. Realtime updates toegevoegd aan de transcriptie en vertaling in de Tolkchat.<br></br>
                    2. De server volledig geherconfigureerd, zodat deze volledig in Europa is geplaatst.<br></br>
                    3. Verplichte geïnformeerde toestemming toegevoegd vóór elke Tolkchat.<br></br>
                    4. Een instellingenmenu toegevoegd in de Tolkchat (nog in ontwikkeling).<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Diverse bugfixes en optimalisaties geïmplementeerd.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.12 (31-08-2024): 💼</h2>
                <p className="reason-description">
                    Algemene functies:<br></br>
                    1. Wachtwoord reset optie toegevoegd.<br></br>
                    2. Twee-factor authenticatie is nu volledig functioneel.<br></br>
                    Organisatie functies:<br></br>
                    1. Het is nu mogelijk om een organisatie aan te maken.<br></br>
                    2. Mogelijkheid om met verschillende organisatie- en piloottypen te werken.<br></br>
                    3. Organisatiecodes toegevoegd zodat gebruikers zich kunnen aansluiten bij hun organisatie.<br></br>
                    4. Organisaties hebben een eigen overzicht met inzichten.<br></br>
                    5. Gebruikers kunnen nu al onze voorwaarden en gelicentieerde documenten downloaden.<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Diverse bugfixes en optimalisaties geïmplementeerd.<br></br>
                    - Een hardnekkige bug in het organisatieoverzicht moet nog worden opgelost.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.11 (20-07-2024): ⚙️</h2>
                <p className="reason-description">
                    Functies:<br></br>
                    1. Alle gebruikersmodellen herschreven om ze voor te bereiden op toekomstige updates.<br></br>
                    2. Optie toegevoegd om alleen een patiënt-ID in te voeren in plaats van een naam voor privacyredenen.<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Diverse bugfixes en optimalisaties geïmplementeerd.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">Wellcom Portaal Patch Notes 1.0.10 (17-05-2024): 🎙️</h2>
                <p className="reason-description">
                    Functies:<br></br>
                    1. De grootte van de microfoonknop vergroot voor betere zichtbaarheid en toegankelijkheid.<br></br>
                    2. De sectie "Over Ons" verwijderd, omdat deze informatie dubbelde op wat al op de website staat.<br></br>
                    3. Een zoekfunctie voor talen toegevoegd met de mogelijkheid om de 10 meest gebruikte talen bovenaan de lijst weer te geven, te beginnen met Arabisch (Syrisch-Libanees).<br></br>
                    - Patiëntinformatie in profielen bevat nu alleen patiënt-ID's voor privacyredenen, zonder namen en achternamen. Gebruikers kunnen ervoor kiezen deze informatie toe te voegen als ze dat willen.<br></br>
                    4. Bij het toevoegen van de web-app aan het startscherm van een telefoon, wordt de titel nu weergegeven als "Wellcom: TolkChat" in plaats van "React App".<br></br>
                    5. "Start Chat" hernoemd naar "Start TolkChat" op het startscherm.<br></br>
                    6. "Voeg Patiënt Toe" hernoemd naar "Voeg Patiënt Toe" op het startscherm.<br></br>
                    7. Het sitepictogram bijgewerkt om het huidige logo weer te geven.<br></br>
                    8. Een nieuw tabblad "Demo Pilot" toegevoegd waar een video kan worden geüpload. Ook een "Technische" pagina en een FAQ-sectie toegevoegd voor aanvullende informatie.<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Diverse bugfixes en optimalisaties geïmplementeerd.<br></br>
                    - Een nieuw logo voor TolkChat ontworpen.<br></br>
                    - Informatie toegevoegd aan de FAQ-pagina's.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">WellCom Portaal Patch Notes 1.0.9 (17-05-2024): 🗣</h2>
                <p className="reason-description">
                    Functies:<br></br>
                    - Spraak-naar-tekst functionaliteit ingeschakeld met ondersteuning voor 148 verschillende talen en dialecten, waardoor toegankelijkheid en bruikbaarheid worden verbeterd.<br></br>
                    - Compatibiliteit uitgebreid naar alle MacBooks, zodat het naadloos werkt op verschillende apparaten, inclusief oudere modellen zoals die van Victor.<br></br>
                    - Alle verwijzingen naar "WellCom" bijgewerkt naar "Wellcom" voor consistentie in het hele platform.<br></br>
                    - Extra optimalisaties geïmplementeerd voor snellere laadtijden van afbeeldingen en andere inhoud, wat de algehele gebruikerservaring verbetert.<br></br>
                    - De mobiele versie verbeterd voor een meer gepolijste en gebruiksvriendelijke interface.<br></br>
                    <br></br>
                    Verbeteringen:<br></br>
                    - Voortdurende backend-optimalisaties om de systeemefficiëntie en prestaties te verbeteren.<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Diverse bugs aangepakt en processen gestroomlijnd om een soepele en ononderbroken service voor alle gebruikers te garanderen.<br></br>
                </p>
            </div>

            <div className="reason">
                <h2 className="reason-title">WellCom Portaal Patch Notes 1.0.8 (16-04-2024): 📩</h2>
                <p className="reason-description">
                    Functies:<br></br>
                    - Twee-staps verificatie e-mails geïmplementeerd voor verbeterde beveiliging, zodat de accounts van gebruikers beschermd blijven.<br></br>
                    - Laadtijden van afbeeldingen geoptimaliseerd door de afbeeldingsgrootte te verkleinen via compressietechnieken, wat resulteert in snellere paginalaadtijden.<br></br>
                    - Een kritieke bug verholpen als gevolg van een database-migratie, waardoor tal van problemen zijn opgelost en de volledige functionaliteit van het platform is hersteld.<br></br>
                    - Serverzijde opschoning geïmplementeerd voor verbeterde systeemprestaties en stabiliteit.<br></br>
                    - De gebruikerservaring verbeterd door de pagina direct op het inlogscherm te starten, zonder onnodige prompts.<br></br>
                    <br></br>
                    Verbeteringen:<br></br>
                    - Diverse backend-optimalisaties en verbeteringen voor een betere systeemefficiëntie.<br></br>
                    <br></br>
                    Bugfixes:<br></br>
                    - Kritieke fouten en problemen opgelost om een ononderbroken service voor alle gebruikers te garanderen.<br></br>
                </p>
            </div>

        </div>
    );
};

export default InfoPage;
