import React, { useState, useEffect } from 'react';
import { getJwtToken } from '../auth';
import config from '../config';
import './mijnbedrijfoverzicht.css';

const reactServer = config.reactServer;

interface Organisation {
    name: string;
    code: string;
    type: string;
    deelnemer: number;
    organisatie_bevestigd: boolean;
    admin: boolean;
    subscriptiontype: string;
    deelnemers: number;
}

interface Member {
    name: string;
    email: string;
    in_organisation: boolean;
}

const SuperAdminPage: React.FC = () => {
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);
    const [allOrganisations, setAllOrganisations] = useState<string[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
    const [orgDetails, setOrgDetails] = useState<{ details: Organisation | null, members: Member[] }>({ details: null, members: [] });

    useEffect(() => {
        const fetchProfile = async () => {
            const token = getJwtToken();
            try {
                const response = await fetch(reactServer + 'account/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const profileData = await response.json();
                setIsSuperAdmin(profileData.is_admin);

                if (profileData.is_admin) {
                    const organisationsResponse = await fetch(reactServer + 'organisation/get_all_details', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!organisationsResponse.ok) {
                        throw new Error(`HTTP error! Status: ${organisationsResponse.status}`);
                    }

                    const data = await organisationsResponse.json();
                    setOrganisations(Object.keys(data).map(orgName => ({ name: orgName, ...data[orgName] })));
                }
            } catch (error) {
                console.error('Error fetching profile or organisations:', error);
            }
        };

        fetchProfile();
    }, []);

    useEffect(() => {
        const fetchAllOrganisations = async () => {
            try {
                const response = await fetch(reactServer + 'organisation/get_all', {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setAllOrganisations(data);
            } catch (error) {
                console.error('Error fetching all organisations:', error);
            }
        };

        fetchAllOrganisations();
    }, []);

    useEffect(() => {
        const fetchOrganisationDetails = async (orgName: string) => {
            const token = getJwtToken();
            try {
                const response = await fetch(`${reactServer}organisation/get_organisation_details/${orgName}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setOrgDetails(data);
            } catch (error) {
                console.error('Error fetching organisation details:', error);
            }
        };

        if (selectedOrg) {
            fetchOrganisationDetails(selectedOrg);
        }
    }, [selectedOrg]);

    const handleCheckboxChange = async (orgName: string, checked: boolean) => {
        const token = getJwtToken();
        try {
            const response = await fetch(reactServer + 'organisation/update_organisatie_bevestigd', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ org_name: orgName, organisatie_bevestigd: checked, super_admin: isSuperAdmin }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setOrganisations(prevState => {
                return prevState.map(org => {
                    if (org.name === orgName) {
                        return { ...org, organisatie_bevestigd: checked };
                    }
                    return org;
                });
            });
        } catch (error) {
            console.error('Error updating organisatie_bevestigd:', error);
        }
    };

    const handleMemberCheckboxChange = async (email: string, checked: boolean) => {
        const token = getJwtToken();
        try {
            const response = await fetch(reactServer + 'organisation/update_member_status', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ org_name: selectedOrg, email: email, in_organisation: checked }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Update local state
            setOrgDetails(prevState => ({
                ...prevState,
                members: prevState.members.map(member =>
                    member.email === email ? { ...member, in_organisation: checked } : member
                ),
            }));
        } catch (error) {
            console.error('Error updating member status:', error);
        }
    };

    if (isSuperAdmin === null) {
        return <div>Loading...</div>;
    }

    if (!isSuperAdmin) {
        return <div>You do not have permission to access this page.</div>;
    }

    return (
        <div className="super-admin-page-container">
            <h2>Organisaties Beheren</h2>
            <table className="organisation-table">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Organisatie Bevestigd</th>
                        <th>Admin</th>
                        <th>Subscription Type</th>
                        <th>Aantal Deelnemers</th>
                    </tr>
                </thead>
                <tbody>
                    {organisations.map((org, index) => (
                        <tr key={index}>
                            <td>{org.name}</td>
                            <td>{org.code}</td>
                            <td>{org.type}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={org.organisatie_bevestigd}
                                    onChange={(e) => handleCheckboxChange(org.name, e.target.checked)}
                                />
                            </td>
                            <td>{org.admin}</td>
                            <td>{org.subscriptiontype}</td>
                            <td>{org.deelnemers}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='organisation-selector'>
                <label htmlFor="organisation-select">Select Organisation:</label>
                <select
                    id="organisation-select"
                    onChange={(e) => setSelectedOrg(e.target.value)}
                    value={selectedOrg || ''}
                >
                    <option value="">Select an organisation</option>
                    {allOrganisations.map(orgName => (
                        <option key={orgName} value={orgName}>{orgName}</option>
                    ))}
                </select>
            </div>
            {selectedOrg && (
                <div>
                    <h3>Details for {selectedOrg}</h3>
                    <table className="organisation-details-table">
                        <thead>
                            <tr>
                                <th>Naam</th>
                                <th>Email</th>
                                <th>In Organisation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orgDetails.members.map((member, index) => (
                                <tr key={index}>
                                    <td>{member.name}</td>
                                    <td>{member.email}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={member.in_organisation}
                                            onChange={(e) => handleMemberCheckboxChange(member.email, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default SuperAdminPage;
