import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './wachtwoordVergeten.css';

import Error from '../notifications/Error';
import Succes from '../notifications/Succes'
import config from '../config';


const reactServer = config.reactServer;

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      email: email,
      frontend_url: window.location.origin
    };

    try {
      const response = await fetch(reactServer + 'account/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || 'Geen verbinding met de server.');
        return;
      }

      const data = await response.json();

      if (data.message === 'Reset email sent') {
        setSuccess('Een e-mail is verzonden om uw wachtwoord opnieuw in te stellen.');
        setEmail('');
        setError('');
      } else {
        setError(data.message);
        setSuccess('');
      }
    } catch (error) {
      setError('Geen verbinding met de server.');
      setSuccess('');
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  const handleCloseSuccess = () => {
    setSuccess('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (error) {
      setError('');
    }
  };

  return (
    <div className='forgot-password-container'>
      {error && <Error message={error} onClose={handleCloseError} />}
      {success && <Succes message={success} onClose={handleCloseSuccess} />}
      <h1 className="page-title">Wachtwoord vergeten</h1>
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <button type="submit">Verzenden</button>
      </form>
      <div className='navigation-links'>
        <Link className='link' to="/login">Terug naar inloggen</Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
